import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function Result({ result }) {
  const perceptionsResult = result.filter(item => item.question.type === 'perceptions')
  const demographicsResult = result.filter(item => item.question.type === 'demographics')
  return (
    <TableContainer component={Paper}>
      
      {/* <div style={{padding:'8px'}}>
              <h5 style={{color:'red'}}>To compete the survey, and to make sure you get paid, you have to click this link:</h5>
              <a href='https://app.prolific.co/submissions/complete?cc=C49CWYD2'>https://app.prolific.co/submissions/complete?cc=C49CWYD2</a>
      </div> */}

      <Table sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Question</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Your Answer</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Who<br></br>Answered <br></br><span style={{ fontWeight: 'bold', color: 'red' }}>Yes</span></TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Who<br></br>Answered <br></br><span style={{ fontWeight: 'bold', color: 'red' }}>No</span></TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Who<br></br>Answered <br></br><span style={{ fontWeight: 'bold', color: 'red' }}>Maybe</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {perceptionsResult?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={{ fontWeight: 'bold' }} component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="left">{row.question.content}</TableCell>
              <TableCell align="left">{row.option.content}</TableCell>
              <TableCell align="left">{row.question.options[0].percentage.toFixed(2)}%</TableCell>
              <TableCell align="left">{row.question.options[1].percentage.toFixed(2)}%</TableCell>
              <TableCell align="left">{row.question.options.length > 2 ? `${row.question.options[2].percentage.toFixed(2)}%` : '/'}</TableCell>
            </TableRow>
          ))}
        </TableBody>

      </Table>
      {/* <br></br><br></br>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">

        {
          demographicsResult?.map((item, index) => (
            <React.Fragment key={index}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>#</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">Question</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">Your Answer</TableCell>
                  {
                    item?.question?.options.map((i, indx) => (
                      <TableCell sx={{ fontWeight: 'bold' }} key={indx} align="left">Who<br></br>Answered <br></br><span style={{ fontWeight: 'bold', color: 'red' }}>{i.content}</span></TableCell>
                    ))
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell sx={{ fontWeight: 'bold' }} component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left">{item.question.content}</TableCell>
                  <TableCell align="left">{item.option.content}</TableCell>
                  {
                    item?.question?.options.map((i, indx) => (
                      <TableCell key={indx} align="left">{i.percentage.toFixed(2)}%</TableCell>
                    ))
                  }
                </TableRow>
              </TableBody>
            </React.Fragment>
          ))
        }
      </Table> */}
    </TableContainer>
  );
}