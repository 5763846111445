import React from 'react'
import ResponsiveAppBar from '../ResponsiveAppBar'
import { Outlet } from 'react-router-dom'

function NavbarLayout() {
  return <>
    <ResponsiveAppBar />
    <Outlet />
  </>
}

export default NavbarLayout