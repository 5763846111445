import axios from 'axios';


// const BASE_URL = 'http://127.0.0.1:8000';
const BASE_URL = 'http://so-human.net';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 
        'Content-Type': 'application/json',
    },
    // withCredentials: true
});


const requestIntercept = axiosPrivate.interceptors.request.use(
    config => {
        // if (!config.headers['Authorization']) {
        //     config.headers['Authorization'] = `Token ${localStorage.getItem('token')}`;
        // }
        return config;
    }, (error) => Promise.reject(error)
);

const responseIntercept = axiosPrivate.interceptors.response.use(
    response => response,
    async (error) => {
        const prevRequest = error?.config;
        // if (error?.response?.status === 401 && !prevRequest?.sent) {
        //     localStorage.removeItem('token')
        // }
        return Promise.reject(error);
    }
);

