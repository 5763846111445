import React, { useState, useEffect } from 'react';
import { Container, Button } from '@mui/material';
import './App.css';
import ResponsiveAppBar from './ResponsiveAppBar';
import Result from './Result';
import { Navigate, useNavigate } from 'react-router-dom';
import axios, { axiosPrivate } from './app/api/axios';
import { LoadingButton } from '@mui/lab';

function App() {

  const navigate = useNavigate()
  const [data, setData] = useState([])

  const [errId, setErrId] = useState('')
  const [errAnswers, setErrAnswers] = useState('')
  const [emailErr, setEmailErr] = useState('')

  const [email, setEmail] = useState('')
  const [answer, setAnswer] = useState([])
  const [profilicID, setProfilicID] = useState('')
  const [age, setAge] = useState('')
  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState([])
  const [showEmail, setShowEmail] = useState(false)
  const [showResultAfterSubmit, setShowResultAfterSubmit] = useState(true)
  const [loading, setLoading] = useState(false)

  const fetchData = () => {
    return fetch("http://so-human.net/api/questions/")
      .then((response) => response.json())
      .then((data) => setData(data));
  }

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    if (!showEmail) {
      setEmail('')
    }
  }, [showEmail])

  const handleAnswerChange = (e, question_id, option_id) => {
    setAnswer(prevS => {
      const updatedAllAnswers = prevS.filter(
        question => question[0] !== question_id
      );
      return [...updatedAllAnswers, [question_id, option_id]]
    }
    )
  }

  const handleSubmit = () => {

    setErrAnswers('')
    setErrId('')
    setEmailErr('')

    async function postData() {
      setLoading(true)
      try {
        const {data} = await axiosPrivate.post('/api/submission/', {
          user_email: email,
          user_prolific_id: profilicID,
          age:age,
          answer: answer
        })

        if(data && showResultAfterSubmit) {
          localStorage.setItem('user',data.user)
          setShowResult(true)
          getResult()
        }

      } catch (e) {
        // if (e.response.status === 401) { navigate('/login') }
        if (e.response.status === 400) { if(e.response.data.user_email.length != 0) { setEmailErr(e.response.data.user_email) } }
        return e;
      } finally {
        setLoading(false)
      }
    }

    if (answer.length !== data.length) {
      setErrAnswers('Answer all the questions please')
      return;
    }
    
    postData()
    
  }

  const getResult = async () => {

    try {
      const {data} = await axiosPrivate.get('/api/result/',{
        params: {
          user : localStorage.getItem('user')
        }
      })
      if(data) {
        setResult(data)
      }
    } catch (e) {
      // if (e.response.status === 401) { navigate('/login') }
      return e;
    }

  }


  // if(!localStorage.getItem('token')) {
  //   return <Navigate to={'/login'} />
  // }

  return (
    <>
      <Container style={{ overflow: 'hidden', overflowY: 'auto' }}>
        {data.length > 0 ?
          (
            !showResult &&
          <div>
            <div>
              <h1>Introduction:</h1>
              <p>
                Your opinion is very important to us and we thank you for agreeing to take this survey.  Please answer each and every question as honestly as you can.  There are no right or wrong answers, and the information you give us will remain confidential.  Your privacy is ensured as we are not asking for your name.
                <br></br>Taking this survey is entirely voluntary.<br></br>
                Thanks once again!
              </p>
            </div>
            {/* <div>
              <h1>Identification:</h1>
            </div> */}
            <h1>Demographics</h1>
            <p>First, please tell us a little about yourself:</p>
            {data &&
              data.slice(0, 5).map((item, index) => {
                return (
                  <div key={index} style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <h5 style={{ marginRight: '4px' }}>{item.content}</h5>
                    <select onChange={(e) => handleAnswerChange(e, item.id, parseInt(e.target.value))} name="cars" id="cars">
                      <option value="" >
                        Choose an option
                      </option>
                      {
                        item.options?.map((option, opIndex) => <option key={opIndex} value={option.id}>{option.content}</option>)
                      }
                    </select>
                  </div>
                )
              })
            }
            <label className="custom-field one">
              {/* <div>
            <p>What is your unique Prolific ID number?</p>
            <input value={profilicID} onChange={(e) => setProfilicID(e.target.value)} type="text" placeholder="profilic id" />
          </div> */}
              <div>
                <p>How old are you?</p>
                <input value={age} onChange={(e) => setAge(e.target.value)} type="text" placeholder="age" />
              </div>
            </label>
            <div>
              <h1>Perceptions about what you consider cheating/infidelity:</h1>
              <p>
                Reflecting on how you feel in general, please answer the following questions.  The response of “yes” says you believe the activity is cheating and “No” means you don’t consider the activity to be cheating and “Maybe” means you are not sure if it is cheating or not. Please answer every item, even if you are unsure of your exact feeling. The questions are in alphabetical order. "Opposite sex" is for description of heterosexual couples and should be changed to same "same sex" for same gender relationships.
              </p>
            </div>
            {data &&
              data.slice(5, 27).map((item, index) => {
                return (
                  <div key={index} style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                    <h5 style={{ marginRight: '4px' }}>{item.content}</h5>
                    <select onChange={(e) => handleAnswerChange(e, item.id, parseInt(e.target.value))} name="cars" id="cars">
                      <option value="" >
                        Choose an option
                      </option>
                      {
                        item.options?.map((option, opIndex) => <option key={opIndex} value={option.id}>{option.content}</option>)
                      }
                    </select>
                  </div>
                )
              })
            }

            <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap'
                  }}>
                    {/* <h5 style={{ marginRight: '4px' }}>Would you like to see the results of this survey?</h5> */}
                    {/* <select onChange={(e) => setShowResultAfterSubmit(!showResultAfterSubmit)} value={showResultAfterSubmit}  name="cars" id="cars">
                      <option value={true} >
                        Yes
                      </option>
                      <option value={false} >
                        No
                      </option>
                    </select> */}
                    <h5 style={{ marginRight: '4px' }}>Would you like to be on our mailing list to hear about our programs when we launch?</h5>
                    <select onChange={(e) => setShowEmail(!showEmail)} value={showEmail}  name="cars" id="cars">
                      <option value={true} >
                        Yes
                      </option>
                      <option value={false} >
                        No
                      </option>
                    </select>
            </div>

            {/* <h5>If you answered “YES” to any of the above 2 questions, please give us your email address. </h5> */}
            {showEmail && <label className="custom-field one">
              <p>Email Address</p>
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder=" " />
            </label>}

            {/* <div>
              <h5 style={{color:'red'}}>To compete the survey, and to make sure you get paid, you have to click this link:</h5>
              <a href='https://app.prolific.co/submissions/complete?cc=C49CWYD2'>https://app.prolific.co/submissions/complete?cc=C49CWYD2</a>
            </div> */}


            <br></br>
            {errAnswers && <h4 style={{color:'white',background:'red', padding:'3px'}}>{errAnswers}</h4>}
            {errId && <h4 style={{color:'white',background:'red', padding:'3px'}}>{errId}</h4>}
            {emailErr && <h4 style={{color:'white',background:'red', padding:'3px'}}>{emailErr}</h4>}
            {/* <Button variant="outlined"  onClick={() => getResult()} sx={{ mb: 5, mr:2 }}>Show Result</Button> */}
            <LoadingButton loading={loading} onClick={handleSubmit} sx={{ mb: 5, mr:4 }} variant="contained">Submit</LoadingButton>
          </div> 
          )
          :
           <h1>Loading ...</h1>
        }
      </Container>
        { showResult && (result.length > 0 ? <Result result={result} /> : <h1>Loading ...</h1>) }
    </>
  );
}

export default App;
