import React, { lazy,Suspense } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

import NavbarLayout from "./layout/NavbarLayout";

const Login = lazy(() => import('./Login'));
const Register = lazy(() => import('./Register'));
const App = lazy(() => import('./App'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));
const Home = lazy(() => import('./pages/Home'));





function Router() {
    return (
        <BrowserRouter>
            <Suspense fallback={<h1></h1>}>
                <Routes>

                    <Route element={<NavbarLayout />} >
                        <Route path="/" element={<Home />} /> 
                        <Route path="/survey" element={<App />} /> 
                        <Route path="/contact" element={<Contact />} /> 
                    </Route>


                    {/* <Route path="login" element={<Login />} /> */}
                    {/* <Route path="register" element={<Register />} /> */}
                    <Route path="404" element={<h1>404</h1>} />
                    <Route path="*" element={<Navigate to="/404" replace />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default Router;
